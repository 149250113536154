<template>
    <div class="page-body">
        <div class="table-body">
            <div class="table-header">
                <div class="handle-box">
                    <table width="100%">
                        <tr>
                            <td style="width: 100px;">
                                工程师名称：
                            </td>
                            <td>
                                <el-input v-model="searchItem.engineerName" size="small"
                                          placeholder="请输入工程师名称"></el-input>
                            </td>
                            <td style="width: 80px">
                                联系电话：
                            </td>
                            <td>
                                <el-input v-model="searchItem.engineerTel" size="small"
                                          placeholder="请输入联系电话"></el-input>
                            </td>
                            <td style="width: 300px ;text-align: center">
                                <el-button type="primary" icon="el-icon-search" size="small" @click="searchBtn">搜索
                                </el-button>
                                <el-button type="primary" icon="el-icon-refresh" size="small" @click="reset">重置
                                </el-button>
                                <el-button type="primary" icon="el-icon-circle-plus" size="small" @click="addEngineer"
                                           v-if="hasAuthority('info_engineer_add')">
                                    增加
                                </el-button>
                            </td>
                            <td width="30%">
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="table-info">
                <div class="table-button">
                    <div>
                        <el-table
                                :data="tableData"
                                :max-height="tableHeight"
                                style="width: 100%;height: 100%;"
                                :border="true"
                                :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                                :cell-style="{padding:'0',height: lineHeight}">
                            <el-table-column type="index" width="50" label="排序" align="center"></el-table-column>
                            <el-table-column prop="engineerName" label="工程师名称" align="center"></el-table-column>
                            <el-table-column prop="engineerTel" label="联系电话" align="center"></el-table-column>
                            <!--<el-table-column prop="number" label="本月单量" align="center"></el-table-column>
                            -->
                            <el-table-column prop="dispatchCount" label="本月派单次数" align="center"></el-table-column>
                            <el-table-column width="150" label="操作" align="center">
                                <template slot-scope="scope">
                                    <div class="operation">
                                        <i class="iconfont iconbianji" title="编辑"
                                           v-if="hasAuthority('info_engineer_update')"
                                           @click="editEngineer(scope.row.id)"></i>
                                        <i class="iconfont iconxiangqing" title="详情"
                                           @click="showEngineer(scope.row.id)"></i>
                                        <i class="iconfont iconshanchu" title="删除"
                                           v-if="hasAuthority('info_engineer_delete')"
                                           @click="delEngineer(scope.row.id)"></i>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="table-data">
                    <Pagination :pageSize="option.pageSize" :pageTotal="totalPage" :pageCurrent.sync="option.pageIndex"
                                :pageList="pageList" @handlePageChange="pageChange" @handleSizeChange="pageSizeChange"/>
                </div>
            </div>
        </div>
        <div class="dialog-form">
            <el-dialog
                    :title="editTitle"
                    :visible.sync="editDialogVisible"
                    :close-on-click-modal="false"
                    width="30%"
                    center>
                <el-form :model="engineerForm" :rules="engineerRules" ref="engineerForm" label-width="100px"
                         class="demo-ruleForm">
                    <el-form-item label="工程师名称" prop="engineerName">
                        <el-input v-model="engineerForm.engineerName"></el-input>
                    </el-form-item>
                    <el-form-item label="联系电话" prop="engineerTel">
                        <el-input v-model="engineerForm.engineerTel"></el-input>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="cancel('engineerForm')">取 消</el-button>
                    <el-button type="primary" @click="submit('engineerForm')" :loading="isLoad">确 定</el-button>
                </span>
            </el-dialog>
        </div>
        <div class="dialog-form">
            <el-dialog
                    :title="infoTitle"
                    :visible.sync="infoDialogVisible"
                    :close-on-click-modal="false"
                    width="50%"
                    center>
                <div>
                    <table style="width: 100%;margin-bottom: 10px">
                        <tr>
                            <td style="width: 80px">
                                工程师名称：
                            </td>
                            <td style="width: 30%">
                                {{engineerDisplay.engineerName}}
                            </td>
                            <td style="width: 70px;padding-left: 20px">
                                联系电话：
                            </td>
                            <td style="width: 30%">
                                {{engineerDisplay.engineerTel}}
                            </td>
                            <td style="width: 70px">
                            </td>
                            <td style="width: 30%">
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 80px;padding-top: 10px">
                                时间搜索：
                            </td>
                            <td style="padding-top: 10px;width: 30%">
                                <el-date-picker
                                        v-model="rangeTime"
                                        type="monthrange"
                                        size="mini"
                                        style="width: 100%"
                                        :clearable=false
                                        value-format="yyyy-MM-dd"
                                        range-separator="~"
                                        @change="searchByTime"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期">
                                </el-date-picker>
                            </td>
                            <td style="width: 70px;padding-left: 20px;padding-top: 10px">
                                单量合计：
                            </td>
                            <td style="width: 30%;padding-top: 10px">
                                {{engineerDisplay.totalAmount}}
                            </td>
                            <td style="width: 70px">
                            </td>
                            <td style="width: 30%">
                            </td>
                        </tr>

                    </table>
                    <el-table
                            :data="dispatchList"
                            :max-height="tableHeight"
                            style="width: 100%;height: 100%;"
                            :border="true"
                            :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                            :cell-style="{padding:'0',height: lineHeight}">
                        <el-table-column type="index" width="50" label="排序" align="center"></el-table-column>
                        <el-table-column prop="serviceType" label="工单类型" align="center"
                                         show-overflow-tooltip>
                            <template slot-scope="scope">
                                <span v-if="scope.row.serviceType == 0">初装</span>
                                <span v-else-if="scope.row.serviceType == 1">加装</span>
                                <span v-else-if="scope.row.serviceType == 2">补装</span>
                                <span v-else-if="scope.row.serviceType == 3">巡检</span>
                                <span v-else-if="scope.row.serviceType == 4">拆卸</span>
                                <span v-else-if="scope.row.serviceType == 5">维护</span>
                                <span v-else-if="scope.row.serviceType == 6">远程处理</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="amount" label="工单量" align="center"
                                         show-overflow-tooltip></el-table-column>
                        <el-table-column prop="createTime" label="派单时间" :formatter="dateFormat" align="center"
                                         show-overflow-tooltip></el-table-column>
                        <el-table-column prop="finishedDate" label="结单时间" :formatter="dateFormat" align="center"
                                         show-overflow-tooltip></el-table-column>
                        <el-table-column prop="companyName" label="客户名称" align="center"
                                         show-overflow-tooltip></el-table-column>
                    </el-table>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="infoDialogVisible = false">确 定</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                pageList: [16, 20, 30, 50],
                tableHeight: '',
                tableData: [],
                dispatchList: [],
                option: {
                    pageSize: 16,
                    pageIndex: 1,
                },
                isLoad: false,
                totalPage: 10,
                editDialogVisible: false,
                infoDialogVisible: false,
                lineHeight: '',
                editTitle: '',
                infoTitle: '',
                searchItem: {
                    engineerTel: '',
                    engineerName: '',
                    pageSize: 16,
                    pageIndex: 1
                },
                type: 0,
                rangeTime: [],
                engineerForm: {
                    id: null,
                    engineerName: '',
                    engineerTel: '',
                },
                engineerRules: {
                    engineerName: [
                        {required: true, message: '请输入工程师名称', trigger: 'blur'},
                    ],
                    engineerTel: [
                        {required: true, message: '请输入联系电话', trigger: 'blur'},
                    ],
                },
                engineerDisplay: {
                    engineerTel: '',
                    engineerName: '',
                    totalAmount: '',
                },
                enId: ''
            };
        },
        methods: {
            pageChange(option) {
                this.searchItem.pageIndex = option;
                this.option.pageIndex = option;
                this.$api.pageEngineer(this.searchItem).then(res => {
                    if (res.code == 200) {
                        this.totalPage = res.data.totalElements;
                        this.tableData = res.data.content;
                    }
                })
            },
            pageSizeChange(val) {
                this.searchItem.pageIndex = val;
                this.$api.pageEngineer(this.searchItem).then(res => {
                    if (res.code == 200) {
                        this.totalPage = res.data.totalElements;
                        this.tableData = res.data.content;
                    }
                })
            },
            reset() {
                this.searchItem = {
                    engineerTel: '',
                    engineerName: '',
                    pageSize: 16,
                    pageIndex: 1
                }
                this.pageChange(1);
            },
            searchBtn() {
                this.pageChange(1);
            },
            addEngineer() {
                this.editDialogVisible = true;
                this.editTitle = '新增工程师信息';
                this.type = 0;
                this.isLoad = false;
                this.engineerForm = {
                    id: null,
                    engineerName: '',
                    engineerTel: '',
                }
            },
            editEngineer(id) {
                this.editDialogVisible = true;
                this.type = 1;
                this.$api.getEngineerById({id: id}).then(res => {
                    if (res.code == 200) {
                        this.editTitle = '编辑‘' + res.data.engineerName + '’工程师信息'
                        this.engineerForm = {
                            id: res.data.id,
                            engineerTel: res.data.engineerTel,
                            engineerName: res.data.engineerName
                        }
                    }
                })
            },
            showEngineer(id) {
                this.initRangeTime();
                this.enId = id;
                this.infoDialogVisible = true;
                this.$api.getEngineerById({id: id}).then(res => {
                    if (res.code == 200) {
                        this.engineerDisplay = {
                            engineerTel: res.data.engineerTel,
                            engineerName: res.data.engineerName,
                        };
                        var total = 0
                        for (const item of res.data.afterSalesList) {
                            total = total + item.amount
                        }
                        this.engineerDisplay.totalAmount = total.toFixed(2)
                        this.dispatchList = res.data.afterSalesList;
                        this.infoTitle = res.data.engineerName + '详情'
                    }
                })
            },
            searchByTime() {
                var endDate = this.getLastDate(this.rangeTime[1]);
                this.$api.getEngineerById({id: this.enId,startDate: this.rangeTime[0], endDate: endDate}).then(res => {
                    if (res.code == 200){
                        this.engineerDisplay = {
                            engineerTel: res.data.engineerTel,
                            engineerName: res.data.engineerName,
                        };
                        var total = 0
                        for (const item of res.data.afterSalesList) {
                            total = total + item.amount
                        }
                        this.engineerDisplay.totalAmount = total.toFixed(2)
                        this.dispatchList = res.data.afterSalesList;
                        this.infoTitle = res.data.engineerName + '详情'
                    }
                })
            },
            getLastDate(date) {
                var lastDate = new Date(date);
                var monthEndDate = new Date(lastDate.getFullYear(), lastDate.getMonth() + 1, 0);
                return monthEndDate.getFullYear() + '-'
                    + (monthEndDate.getMonth() + 1 < 10 ? '0' + (monthEndDate.getMonth() + 1) : (monthEndDate.getMonth() + 1))
                    + '-' + monthEndDate.getDate()
            },
            delEngineer(id) {
                this.$confirm('你确定要售后工程师信息吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    center: true
                }).then(() => {
                    this.$api.deleteEngineer({id: id}).then(res => {
                        if (res.code == 200) {
                            this.$message.success(res.message)
                            this.pageChange(1)
                        } else {
                            this.$message.error(res.message)
                        }
                    })
                }).catch(() => {
                })
            }
            ,

            cancel(formName) {
                this.$refs[formName].resetFields();
                this.editDialogVisible = false;
            }
            ,
            submit(formName) {
                this.isLoad = true;
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (this.type == 0) {
                            this.$api.saveEngineer(this.engineerForm).then(res => {
                                if (res.code == 200) {
                                    this.pageChange(1);
                                    this.editDialogVisible = false;
                                    this.$message.success(res.message)
                                    this.isLoad = false;
                                } else {
                                    this.isLoad = false;
                                    this.$message.error(res.message)
                                }
                            })
                        } else {
                            this.$api.updateEngineer(this.engineerForm).then(res => {
                                if (res.code == 200) {
                                    this.pageChange(1);
                                    this.editDialogVisible = false;
                                    this.$message.success(res.message)
                                    this.isLoad = false;
                                } else {
                                    this.isLoad = false;
                                    this.$message.error(res.message)
                                }
                            })
                        }
                    } else {
                        this.isLoad = false;
                        return false;
                    }
                });
            }
            ,
            initRangeTime() {
                this.rangeTime = [];
                var nowDate = new Date();
                var firstDate = nowDate.getFullYear() + '-'
                    + (nowDate.getMonth() < 10 ? '0' + (nowDate.getMonth() + 1) : (nowDate.getMonth() + 1)) + '-01'
                var lastDate = nowDate.getFullYear() + '-'
                    + (nowDate.getMonth() < 10 ? '0' + (nowDate.getMonth() + 1) : (nowDate.getMonth() + 1)) + '-' +
                    +(nowDate.getDate() < 10 ? '0' + nowDate.getDate() : nowDate.getDate())
                this.rangeTime.push(firstDate)
                this.rangeTime.push(lastDate)
            }
            ,
            dateFormat(row, column) {
                let data = row[column.property]
                let dt = new Date(data)
                var year = dt.getFullYear();
                var month = (dt.getMonth() + 1) < 10 ? 0 + '' + (dt.getMonth() + 1) : ((dt.getMonth() + 1))
                var date = dt.getDate() < 10 ? 0 + '' + dt.getDate() : (dt.getDate())
                return year + '-' + month + '-' + date
            }
            ,
            changeDateFormat(date) {
                if (date == null || date == '' || date == undefined) {
                    return '';
                } else {
                    let dt = new Date(date)
                    var year = dt.getFullYear();
                    var month = (dt.getMonth() + 1) < 10 ? 0 + '' + (dt.getMonth() + 1) : ((dt.getMonth() + 1))
                    var date = dt.getDate() < 10 ? 0 + '' + dt.getDate() : (dt.getDate())
                    return year + '-' + month + '-' + date
                }
            }
            ,
        },
        created() {
            this.pageChange(1);
            this.tableHeight = (window.innerHeight) * 0.76
            if (window.screen.height > 900) {
                this.lineHeight = window.screen.height * 0.038 + 'px';
            } else {
                this.lineHeight = window.screen.height * 0.033 + 'px';
            }
        }
    };
</script>
<style lang="scss" scoped>
    .iconfont {
        font-family: "iconfont" !important;
        font-size: 24px;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin-top: 3px;
        display: block;
        cursor: pointer;
    }

    .iconbianji {
        color: #20a0ff;
        margin-right: 20px;
    }

    .iconshanchu {
        margin-left: 20px;
        color: red;
    }

    .page-body {
        background-color: #FFFFFF;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .table-body {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .table-header {
        height: 6%;
        margin-top: 0.5%;
        background-color: #FFFFFF;
    }

    .handle-box {
        padding: 5px;
    }

    .table-info {
        height: 94%;
        margin-top: 5px;
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
    }

    .table-button {
        flex: 1;
        margin: 0 1%;
    }

    .table-data {
        height: 6%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .operation {
        display: flex;
        justify-content: center;
    }


    td {
        text-align: right;
        white-space: nowrap; /*控制单行显示*/
        overflow: hidden; /*超出隐藏*/
        text-overflow: ellipsis; /*隐藏的字符用省略号表示*/
    }

    .dialog-form td {
        text-align: left;
    }
</style>